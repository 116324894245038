import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UiModule } from './ui/ui.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from './material-ui/material-ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginService } from './services/login.service';
import { UserService } from './services/user.service';
import { SessionService } from './services/session.service';
import { ErrorStateMatcher, MAT_DATE_LOCALE, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginGuard } from './guards/login-guard';
import { LoginHttpInterceptor } from './interceptors/login-http-interceptor';
import { HotkeyModule } from 'angular2-hotkeys';
import { HomepageComponent } from './components/homepage/homepage.component';
import { StudioComponent } from './components/studio/studio.component';
import { CommunicationsComponent } from './components/communications/communications.component';
import { GamesComponent } from './components/games/games.component';
import { AppService } from './services/app.service';
import { AdministrationComponent } from './components/administration/administration.component';
import { UsersComponent } from './components/administration/users/users.component';
import { DataTableModule } from '@pascalhonegger/ng-datatable';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MyAccountComponent } from './components/user/my-account.component';
import { GameContainerComponent } from './components/games/game-container/game-container.component';
import { LocationsComponent } from './components/games/locations/locations.component';
import { DisciplinesComponent } from './components/games/disciplines/disciplines.component';
import { DisciplinesService } from './services/disciplines.service';
import { ManageGamesComponent } from './components/administration/games/manage-games.component';
import { ManageDisciplinesComponent } from './components/administration/games/manage-disciplines/manage-disciplines.component';
import { InstitutionsService } from './services/institutions.service';
import { InstitutionsComponent } from './components/games/institutions/institutions.component';
import { ManageInstitutionsComponent } from './components/administration/games/manage-institutions/manage-institutions.component';
import { MiniGamesComponent } from './components/games/mini-games/mini-games.component';
import { ManageLocationsComponent } from './components/administration/games/manage-locations/manage-locations.component';
import { LocationsService } from './services/locations.service';
import { MiniGamesService } from './services/mini-games.service';
import { ManageMiniGamesComponent } from './components/administration/games/manage-mini-games/manage-mini-games.component';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { WarriorComponent } from './components/games/warrior/warrior.component';
import { SpaceComponent } from './components/games/space/space.component';
import { ProjectItemComponent } from './components/studio/project-item/project-item.component';
import { CommunicationsSectionsComponent } from './components/communications/communications-sections/communications-sections.component';
import { GamesSelectorComponent } from './components/games/games-selector/games-selector.component';
import { ComPersonalInfoComponent } from './components/communications/com-personal-info/com-personal-info.component';
import { ComOverallCardComponent } from './components/communications/com-overall-card/com-overall-card.component';
import { СomPersonalChallengesComponent } from './components/communications/com-personal-info/com-personal-challenges/com-personal-challenges.component';
import { ProjectSidebarComponent } from './components/studio/project-sidebar/project-sidebar.component';
import { UserSkillCardsComponent } from './components/studio/user-skills-cards/user-skills-cards.component';
import { StudioSkillCardComponent } from './components/studio/user-skills-cards/studio-skill-card/studio-skill-card.component';
import { ComLatestActivityComponent } from './components/communications/com-personal-info/com-latest-activity/com-latest-activity.component';
import { FriendsService } from './services/friends.service';
import { TeamSectionComponent } from './components/communications/team-section/team-section.component';
import { TeamSquadComponent } from './components/communications/team-section/team-squad/team-squad.component';
import { TeamProjectPlanComponent } from './components/communications/team-section/team-project-plan/team-project-plan.component';
import { TeamProjectDriveComponent } from './components/communications/team-section/team-project-drive/team-project-drive.component';
import { TeamManageUserComponent } from './components/communications/team-section/team-manage-user/team-manage-user.component';
import { SchoolSectionComponent } from './components/communications/school-section/school-section.component';
import { SchoolSquadComponent } from './components/communications/school-section/school-squad/school-squad.component';
import { SchoolManageTeamComponent } from './components/communications/school-section/school-manage-team/school-manage-team.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { NetworkSectionComponent } from './components/communications/network-section/network-section.component';
import { SchoolProjectCalendarComponent } from './components/communications/school-section/school-project-calendar/school-project-calendar.component';
import { StudentQuestionnaireComponent } from './components/questionnaire/student-questionnaire/student-questionnaire.component';
import { QuestionnaireGuard } from './guards/questionnaire-guard';
import { TeamsService } from './services/team.service';
import { ProjectsService } from './services/projects.service';
import { TasksService } from './services/tasks.service';
import { UsersService } from './services/users.service';
import { NetworkService } from './services/network.service';
import { ActivitiesService } from './services/activities.service';
import { QuestionnaireService } from './services/questionnaire.service';
import { UserExperienceSkillsComponent } from './components/user/user-experience-skills/user-experience-skills.component';
import { ManageSchoolsComponent } from './components/administration/schools/manage-schools.component';
import { ManageStudentsComponent } from './components/administration/schools/manage-students/manage-students.component';
import { ManageTeachersComponent } from './components/administration/schools/manage-teachers/manage-teachers.component';
import { ManageMentorsComponent } from './components/administration/schools/manage-mentors/manage-mentors.component';
import { ManageStudentMentorsComponent } from './components/administration/schools/manage-student-mentors/manage-student-mentors.component';
import { TeacherQuestionnaireComponent } from './components/questionnaire/teacher-questionnaire/teacher-questionnaire.component';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire/questionnaire.component';
import { InviteService } from './services/invite.service';
import { ProfileComponent } from './components/user/profile/profile.component';
import { NavProgressService } from './services/nav-progress.service';
import { InfoBoxComponent } from './components/questionnaire/info-box/info-box.component';
import { QuestionnaireSuccessComponent } from './components/questionnaire/questionnaire-success/questionnaire-success.component';
import { QuestionnaireThemeSelectorComponent } from './components/questionnaire/questionnaire-theme-selector/questionnaire-theme-selector.component';
import { UserAvatarSelectorComponent } from './components/questionnaire/user-avatar-selector/user-avatar-selector.component';
import { ManageGroupsComponent } from './components/administration/schools/manage-groups/manage-groups.component';
import { GroupsService } from './services/groups.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChatsService } from './services/chat.service';
import { DocumentsService } from './services/documents.service';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { WorkspaceComponent } from './components/communications/workspace/workspace.component';
import { RoadmapComponent } from './components/communications/workspace/roadmap/roadmap.component';
import { TaskCardComponent } from './components/communications/workspace/task-card/task-card.component';
import { WorkplaceTasksComponent } from './components/communications/workspace/tasks/workplace-tasks.component';
// import { DragScrollModule } from 'ngx-drag-scroll';
import { TasksColumnComponent } from './components/communications/workspace/tasks/tasks-column/tasks-column.component';
import { CalendarComponent } from './components/communications/calendar/calendar.component';
import { CommunicationInfoComponent } from './components/communications/communication-info/communication-info.component';
import { TeamInfoComponent } from './components/communications/communication-info/team-info/team-info.component';
import { TeamChartComponent } from './components/communications/communication-info/team-info/team-chart/team-chart.component';
import { UserInfoComponent } from './components/communications/communication-info/user-info/user-info.component';
import { UserInfoChartComponent } from './components/communications/communication-info/user-info/user-info-chart/user-info-chart.component';
import { CreateTaskComponent } from './components/communications/workspace/create-task/create-task.component';
import { TaskTemplateComponent } from './components/communications/workspace/create-task/task-template/task-template.component';
import { CommmunicationInfoVer2Component } from './components/communications/communication-info/commmunication-info-ver-2/commmunication-info-ver-2.component';
import { ManageTaskComponent } from './components/communications/workspace/manage-task/manage-task.component';
import { TaskToolsComponent } from './components/communications/workspace/manage-task/task-activities/task-tools.component';
import { StudioManageProjectComponent } from './components/studio/studio-manage-project/studio-manage-project.component';
import { UserDataInfoComponent } from './components/communications/communication-info/user-info/user-data-info/user-data-info.component';
// import { DriveComponent } from './components/communications/workspace/manage-task/task-activities/drive/drive.component';
import { StartScreenComponent } from './components/questionnaire/start-screen/start-screen.component';
import { VotesTopicsService } from './services/votes-topics.service';
import { ManageVotesComponent } from './components/administration/manage-votes/manage-votes.component';
import { ThemeViewComponent } from './components/questionnaire/questionnaire-theme-selector/theme-view/theme-view.component';
import { SuggestThemeComponent } from './components/questionnaire/questionnaire-theme-selector/suggest-theme/suggest-theme.component';
import { ManageSchoolComponent } from './components/administration/manage-school/manage-school.component';
import { VotesResultsComponent } from './components/administration/manage-votes/votes-results/votes-results.component';
import { SubmitSelectedThemeComponent } from './components/questionnaire/submit-selected-theme/submit-selected-theme.component';
import { StudentsGamesComponent } from './components/games/students-games/students-games.component';
import { ServerGameContainerComponent } from './components/games/server-game-container/server-game-container.component';
import { GamesCategoriesComponent } from './components/games/games-categories/games-categories.component';
import { GamesListComponent } from './components/games/games-list/games-list.component';
import { AllMinigamesComponent } from './components/games/mini-games/all-minigames/all-minigames.component';
import { AllDisciplinesComponent } from './components/games/disciplines/all-disciplines/all-disciplines.component';
import { AllInstitutionsComponent } from './components/games/institutions/all-institutions/all-institutions.component';
import { AllLocationsComponent } from './components/games/locations/all-locations/all-locations.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { MenuModule } from './menu/menu.module';
import { StudentsService } from './services/students.service';
import { StudentTypesTableComponent } from './components/communications/communication-info/team-info/student-types-table/student-types-table.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ControlListComponent } from './components/communications/workspace/manage-task/control-list/control-list.component';
import { HistoryComponent } from './components/communications/workspace/manage-task/task-activities/history/history.component';
import { SubtasksService } from './services/subtasks.service';
import { TaskStudentsComponent } from './components/communications/workspace/manage-task/task-students/task-students.component';
import { SimpleStageViewComponent } from './components/communications/workspace/tasks/simple-stage-view/simple-stage-view.component';
import { SimpleCardComponent } from './components/communications/workspace/tasks/simple-stage-view/simple-card/simple-card.component';
import { RolesService } from './services/roles.service';
import { PreUsersSelectComponent } from './components/administration/schools/pre-users-select/pre-users-select.component';
import { GamesViewComponent } from './components/games/game-view/game-view.component';
import { ManageOrganizationsComponent } from './components/administration/manage-organizations/manage-organizations.component';
import { DriveService } from './services/drive.service';
import { TaskDriveComponent } from './components/communications/workspace/manage-task/task-activities/task-drive/task-drive.component';
import { TaskChatComponent } from './components/communications/workspace/manage-task/task-activities/task-chat/task-chat.component';
import { TeamBoxComponent } from './components/communications/communication-info/commmunication-info-ver-2/team-box/team-box.component';
import { DatePipe } from '@angular/common';
import { NotificationService } from './services/notification.service';
import { ChangePasswordComponent } from './components/user/change-password/change-password.component';
import { SkillsChartComponent } from './components/communications/communication-info/team-info/skills-chart/skills-chart.component';
import { QuestionControlService } from './services/question-control.service';
import { DynamicFormComponent } from './components/questionnaire/questionnaire-v2/dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from './components/questionnaire/questionnaire-v2/dynamic-form-question/dynamic-form-question.component';
import { UserInfoFormComponent } from './components/questionnaire/user-info-form/user-info-form.component';
import { StudentGameViewComponent } from './components/games/game-view/student-game-view/student-game-view.component';
import { ViewContainerComponent } from './components/studio/view-container/view-container.component';
import { ImageGeneratoComponent } from './components/studio/image-generator/image-generator.component';
import { WelcomeStudentDialogComponent } from './components/questionnaire/welcome-student-dialog/welcome-student-dialog.component';
import { UserSkillsChartComponent } from './components/communications/communication-info/user-info/user-skills-chart/user-skills-chart.component';
import { TeamInfoUserBoxComponent } from './components/communications/communication-info/team-info/user-box/team-info-user-box.component';
import { AdminGuard } from './guards/admin-guard';
import { ForbiddenComponent } from './ui/forbidden/forbidden.component';
import { ProjectStatisticComponent } from './components/communications/project-statistic/project-statistic.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NgxGlideModule } from 'ngx-glide';
import { ManageProjectsComponent } from './components/administration/manage-projects/manage-projects.component';
import { ManageInvitationsComponent } from './components/administration/manage-invitations/manage-invitations.component';
import { ImageGeneratorDalleComponent } from './components/studio/image-generator-dalle/image-generator-dalle.component';
import { TabsImageComponent } from './components/studio/image-generator/tabs/tabs-image.component';
import { TabImageComponent } from './components/studio/image-generator/tabs/tab-image.component';
import { FireworkComponent } from './components/ui/firework/firework.component';
import { CdkDrag, CdkDragHandle, CdkDragPlaceholder, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { ExamplesComponent } from './components/examples/examples.component';
import { HomepageDashboardComponent } from './components/homepage/homepage-dashboard/homepage-dashboard.component';
import { ManageSurveysComponent } from './components/administration/schools/manage-surveys/manage-surveys.component';
import { SurveysComponent } from './components/surveys/surveys/surveys.component';
import { SurveysManagerComponent } from './components/administration/surveys-manager/surveys-manager.component';
import { ManageSurveysAnswersComponent } from './components/administration/schools/manage-surveys-answers/manage-surveys-answers.component';
import { AiStudioComponent } from './components/studio/ai-studio/components/ai-studio/ai-studio.component';
// import { NgxGlideModule } from 'ngx-glide';
// import { NgxEditorModule } from 'ngx-editor';

const config: SocketIoConfig = environment.socketUrl;

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        HomepageComponent,
        StudioComponent,
        CommunicationsComponent,
        GamesComponent,
        AdministrationComponent,
        UsersComponent,
        MyAccountComponent,
        GameContainerComponent,
        LocationsComponent,
        DisciplinesComponent,
        ManageGamesComponent,
        ManageDisciplinesComponent,
        InstitutionsComponent,
        ManageInstitutionsComponent,
        MiniGamesComponent,
        ManageLocationsComponent,
        ManageMiniGamesComponent,
        WarriorComponent,
        SpaceComponent,
        ProjectItemComponent,
        CommunicationsSectionsComponent,
        GamesSelectorComponent,
        ComPersonalInfoComponent,
        ComOverallCardComponent,
        СomPersonalChallengesComponent,
        ProjectSidebarComponent,
        UserSkillCardsComponent,
        StudioSkillCardComponent,
        ComLatestActivityComponent,
        TeamSectionComponent,
        TeamSquadComponent,
        TeamProjectPlanComponent,
        TeamProjectDriveComponent,
        TeamManageUserComponent,
        SchoolSectionComponent,
        SchoolSquadComponent,
        SchoolManageTeamComponent,
        NetworkSectionComponent,
        SchoolProjectCalendarComponent,
        StudentQuestionnaireComponent,
        UserExperienceSkillsComponent,
        ManageSchoolsComponent,
        ManageStudentsComponent,
        ManageTeachersComponent,
        ManageMentorsComponent,
        ManageStudentMentorsComponent,
        TeacherQuestionnaireComponent,
        QuestionnaireComponent,
        ProfileComponent,
        InfoBoxComponent,
        QuestionnaireSuccessComponent,
        QuestionnaireThemeSelectorComponent,
        UserAvatarSelectorComponent,
        ManageGroupsComponent,
        WorkspaceComponent,
        RoadmapComponent,
        TaskCardComponent,
        WorkplaceTasksComponent,
        TasksColumnComponent,
        CalendarComponent,
        CommunicationInfoComponent,
        TeamInfoComponent,
        TeamChartComponent,
        UserInfoComponent,
        UserInfoChartComponent,
        CreateTaskComponent,
        TaskTemplateComponent,
        CommmunicationInfoVer2Component,
        ManageTaskComponent,
        TaskToolsComponent,
        StudioManageProjectComponent,
        UserDataInfoComponent,
        // DriveComponent,
        StartScreenComponent,
        ManageVotesComponent,
        ThemeViewComponent,
        SuggestThemeComponent,
        ManageSchoolComponent,
        VotesResultsComponent,
        SubmitSelectedThemeComponent,
        StudentsGamesComponent,
        ServerGameContainerComponent,
        GamesCategoriesComponent,
        GamesListComponent,
        AllMinigamesComponent,
        AllDisciplinesComponent,
        AllInstitutionsComponent,
        AllLocationsComponent,
        StudentTypesTableComponent,
        // ChatComponent,
        ControlListComponent,
        HistoryComponent,
        TaskStudentsComponent,
        SimpleStageViewComponent,
        SimpleCardComponent,
        PreUsersSelectComponent,
        GamesViewComponent,
        ManageOrganizationsComponent,
        TaskDriveComponent,
        TaskChatComponent,
        TeamBoxComponent,
        ChangePasswordComponent,
        SkillsChartComponent,
        DynamicFormComponent,
        DynamicFormQuestionComponent,
        UserInfoFormComponent,
        StudentGameViewComponent,
        ViewContainerComponent,
        ImageGeneratoComponent,
        WelcomeStudentDialogComponent,
        UserSkillsChartComponent,
        TeamInfoUserBoxComponent,
        ForbiddenComponent,
        ProjectStatisticComponent,
        ManageProjectsComponent,
        ManageInvitationsComponent,
        ImageGeneratorDalleComponent,
        TabsImageComponent,
        TabImageComponent,
        FireworkComponent,
        ExamplesComponent,
        HomepageDashboardComponent,
        ManageSurveysComponent,
        SurveysComponent,
        SurveysManagerComponent,
        ManageSurveysAnswersComponent,
        AiStudioComponent,
    ],
    imports: [
        BrowserModule,
        MaterialModule,
        MatMomentDateModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        UiModule,
        NgxGlideModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        DataTableModule,
        NgxFileDragDropModule,
        HighchartsChartModule,
        NgxDocViewerModule,
        MenuModule,
        AngularEditorModule,
        HotkeyModule.forRoot(),
        // DragScrollModule,
        SocketIoModule.forRoot(config),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        CdkDropList,
        CdkDrag,
        CdkDragPlaceholder,
        CdkDropListGroup,
        CdkDragHandle,
    ],
    providers: [
        LoginService,
        LoginGuard,
        AdminGuard,
        QuestionnaireGuard,
        UserService,
        SessionService,
        AppService,
        DisciplinesService,
        InstitutionsService,
        LocationsService,
        MiniGamesService,
        FriendsService,
        TeamsService,
        ProjectsService,
        TasksService,
        QuestionnaireService,
        UsersService,
        ActivitiesService,
        NetworkService,
        InviteService,
        NavProgressService,
        GroupsService,
        ChatsService,
        DocumentsService,
        VotesTopicsService,
        StudentsService,
        SubtasksService,
        RolesService,
        DriveService,
        Title,
        DatePipe,
        NotificationService,
        QuestionControlService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoginHttpInterceptor,
            multi: true,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
