import { Component } from '@angular/core';

@Component({
  selector: 'app-ai-studio',
  templateUrl: './ai-studio.component.html',
  styleUrls: ['./ai-studio.component.scss']
})
export class AiStudioComponent {

}
