import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
import { AdministrationComponent } from './components/administration/administration.component';
import { ManageGamesComponent } from './components/administration/games/manage-games.component';
import { ManageOrganizationsComponent } from './components/administration/manage-organizations/manage-organizations.component';
import { ManageSchoolComponent } from './components/administration/manage-school/manage-school.component';
import { ManageSchoolsComponent } from './components/administration/schools/manage-schools.component';
import { UsersComponent } from './components/administration/users/users.component';
import { TeamInfoComponent } from './components/communications/communication-info/team-info/team-info.component';
import { UserInfoComponent } from './components/communications/communication-info/user-info/user-info.component';
import { CommunicationsComponent } from './components/communications/communications.component';
import { ManageTaskComponent } from './components/communications/workspace/manage-task/manage-task.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ExamplesComponent } from './components/examples/examples.component';
import { AllDisciplinesComponent } from './components/games/disciplines/all-disciplines/all-disciplines.component';
import { GameContainerComponent } from './components/games/game-container/game-container.component';
import { GamesViewComponent } from './components/games/game-view/game-view.component';
import { StudentGameViewComponent } from './components/games/game-view/student-game-view/student-game-view.component';
import { GamesCategoriesComponent } from './components/games/games-categories/games-categories.component';
import { GamesComponent } from './components/games/games.component';
import { AllInstitutionsComponent } from './components/games/institutions/all-institutions/all-institutions.component';
import { AllLocationsComponent } from './components/games/locations/all-locations/all-locations.component';
import { AllMinigamesComponent } from './components/games/mini-games/all-minigames/all-minigames.component';
import { ServerGameContainerComponent } from './components/games/server-game-container/server-game-container.component';
import { SpaceComponent } from './components/games/space/space.component';
import { WarriorComponent } from './components/games/warrior/warrior.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire/questionnaire.component';
import { StudentQuestionnaireComponent } from './components/questionnaire/student-questionnaire/student-questionnaire.component';
import { ImageGeneratorDalleComponent } from './components/studio/image-generator-dalle/image-generator-dalle.component';
import { ImageGeneratoComponent } from './components/studio/image-generator/image-generator.component';
import { StudioManageProjectComponent } from './components/studio/studio-manage-project/studio-manage-project.component';
import { StudioComponent } from './components/studio/studio.component';
import { ViewContainerComponent } from './components/studio/view-container/view-container.component';
import { MyAccountComponent } from './components/user/my-account.component';
import { ProfileComponent } from './components/user/profile/profile.component';
import { AdminGuard } from './guards/admin-guard';
import { LoginGuard } from './guards/login-guard';
import { QuestionnaireGuard } from './guards/questionnaire-guard';
import { ForbiddenComponent } from './ui/forbidden/forbidden.component';
import { ForgotPasswordComponent } from './ui/login/forgot-password/forgot-password.component';
import { LoginComponent } from './ui/login/login.component';
import { ResetPasswordComponent } from './ui/login/reset-password/reset-password.component';
import { RegisterComponent } from './ui/register/register.component';
import { SignUpStudentMentorComponent } from './ui/register/sign-up-student-mentor/sign-up-student-mentor.component';
import { SurveysComponent } from './components/surveys/surveys/surveys.component';
import { SurveysManagerComponent } from './components/administration/surveys-manager/surveys-manager.component';
import { AiStudioComponent } from './components/studio/ai-studio/components/ai-studio/ai-studio.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: { animation: 'page' },
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: { animation: 'page' },
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        data: { animation: 'page' },
    },
    {
        path: 'sign-up',
        component: RegisterComponent,
        data: { animation: 'page' },
    },
    {
        path: 'sign-up-student',
        component: SignUpStudentMentorComponent,
        data: { animation: 'page' },
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'home',
        component: HomepageComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'studio',
        component: StudioComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'communications',
        component: CommunicationsComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'games',
        component: GamesComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'games/game',
        component: GameContainerComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'student-games/:id',
        component: StudentGameViewComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    // {
    //     path: 'administration',
    //     component: AdministrationComponent,
    //     canActivate: [LoginGuard, QuestionnaireGuard],
    //     data: { animation: 'page' },
    // },
    // {
    //     path: 'administration/:component',
    //     component: AdministrationComponent,
    //     canActivate: [LoginGuard, QuestionnaireGuard],
    //     data: { animation: 'page' },
    // },
    {
        path: 'manage-games',
        component: ManageGamesComponent,
        canActivate: [LoginGuard, AdminGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'manage-users',
        component: UsersComponent,
        canActivate: [LoginGuard, AdminGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'manage-surveys',
        component: SurveysManagerComponent,
        canActivate: [LoginGuard, AdminGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'manage-schools',
        component: ManageSchoolsComponent,
        canActivate: [LoginGuard, AdminGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'manage-school',
        component: ManageSchoolComponent,
        canActivate: [LoginGuard, AdminGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'manage-organizations',
        component: ManageOrganizationsComponent,
        canActivate: [LoginGuard, QuestionnaireGuard, AdminGuard],
        data: { animation: 'page' },
    },
    {
        path: 'my-account',
        component: MyAccountComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'warrior',
        component: WarriorComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'space-game',
        component: SpaceComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'communications/team/:id',
        component: TeamInfoComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'communications/user/:id',
        component: UserInfoComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'communications/manage-task/:id',
        component: ManageTaskComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'manage-project',
        component: StudioManageProjectComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'studio/view-project/:id',
        component: ViewContainerComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'generate-images',
        component: ImageGeneratoComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'generate-images-dall-e',
        component: ImageGeneratorDalleComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'ai-studio',
        component: AiStudioComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'games-by-category',
        component: GamesCategoriesComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'mini-games-all',
        component: AllMinigamesComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'disciplines',
        component: AllDisciplinesComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'institutions',
        component: AllInstitutionsComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'locations',
        component: AllLocationsComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    // {
    //   path: 'login',
    //   component: LoginComponent,
    //   data: {animation: 'page'}
    // },
    //  {
    //   path: 'reset-password/:token',
    //   component: ResetPasswordComponent,
    //   data: { animation: 'page' }
    // },
    {
        path: 'questionnaire',
        component: QuestionnaireComponent,
        canActivate: [LoginGuard],
        data: { animation: 'page' },
    },
    {
        path: 'games-students',
        component: ServerGameContainerComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'games-view/:id',
        component: GamesViewComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
    {
        path: 'examples',
        component: ExamplesComponent,
        canActivate: [LoginGuard],
        data: { animation: 'page' },
    },

    {
        path: 'surveys',
        component: SurveysComponent,
        canActivate: [LoginGuard],
        data: { animation: 'page' },
    },

    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent,
    },
    {
        path: '**',
        component: HomepageComponent,
        canActivate: [LoginGuard, QuestionnaireGuard],
        data: { animation: 'page' },
    },
];

// configures NgModule imports and exports
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
