import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Url } from '../url';

enum Level {
    junior,
    middle,
    senior,
    professor,
}

type LevelTypes = keyof typeof Level;

export interface ApplicationType {
    type: string;
    color: string;
    icon: string;
}

export interface ProjectPreview {
    id: number;
    name: string;
    image_path: string;
    type: LevelTypes;
    description: string;
    applicationType: ApplicationType;
    link: string;
    loading?: boolean;
    downloadable?: boolean;
    outerLink?: string;
}

@Injectable({
    providedIn: 'root',
})
export class StudioService {
    tools = [
        {
            id: 112,
            name: this.translate.instant('studio.tools_block.create_tasks'),
            image_path: '/assets/studio/create-tasks-tools.png',
            loading: false,
            link: '/manage-project',
            applicationType: { type: 'creator', color: '#2AE854', icon: 'ph-plus' },
            description: this.translate.instant('studio.tools_block.create_tasks_desc'),
        },
        {
            id: 1132,
            name: this.translate.instant('studio.tools_block.create_image'),
            image_path: '/assets/studio/create-image-tools.png',
            loading: false,
            link: '/generate-images',
            applicationType: { type: 'creator', color: '#2AE854', icon: 'ph-plus' },
            description: this.translate.instant('studio.tools_block.create_image_desc'),
        },
        {
            id: 13,
            name: this.translate.instant('studio.tools_block.unispher_task_database_bridge_for_unity'),
            image_path: '/assets/studio/taks-data-base.png',
            loading: true,
            downloadable: true,
            link: '',
            applicationType: { type: 'plugin', color: '#28C9C9', icon: 'ph-folder' },
            description: this.translate.instant('studio.tools_block.unity_database_desc'),
        },
        {
            id: 14,
            name: "AI Studio (Flowise)",
            image_path: '/assets/studio/dalle-ai.jpg',
            loading: false,
            link: '/ai-studio',
            applicationType: { type: 'creator', color: '#2AE854', icon: 'ph-plus' },
            description: "AI Studio is a tool that allows you to create AI without coding.",
        },
        // {
        //     id: 77,
        //     name: this.translate.instant('studio.tools_block.create_image'),
        //     image_path: '/assets/studio/dalle-ai.jpg',
        //     loading: false,
        //     downloadable: false,
        //     link: '/generate-images-dall-e',
        //     applicationType: { type: 'creator', color: '#2AE854', icon: 'ph-plus' },
        //     tags: [{ color: '#F252FD', name: 'AI' }],
        //     description: this.translate.instant('studio.tools_block.create_image_desc'),
        // },
        {
            id: 82,
            name: this.translate.instant('studio.tools_block.roblox_plugin'),
            image_path: '/assets/studio/roblox-preview.jpg',
            loading: false,
            downloadable: false,
            link: 'https://create.roblox.com/store/asset/16909692485/UnispherBridge',
            outerLink: 'https://create.roblox.com/store/asset/16909692485/UnispherBridge',
            applicationType: { type: 'plugin', color: '#28C9C9', icon: 'ph-folder' },
            tags: [{ color: '#1add44', name: '', icon: 'ph-arrow-square-out' }],
            description: this.translate.instant('studio.tools_block.roblox_plugin_desc'),
        },
    ];

    constructor(
        private http: HttpClient,
        public translate: TranslateService,
    ) {}

    getFakeProjects() {
        return [
            {
                id: 14,
                name: 'Hula Game Final Scenario',
                image_path: '/assets/studio/1.jpg',
                type: 'junior',
                link: '',
                description: 'Project description: dolor sit amet, consectetur adipiscing elit, sed do',
            },
            {
                id: 17,
                name: 'Hula Game Initial Scenario',
                image_path: '/assets/studio/2.jpg',
                type: 'middle',
                link: '',
                description: 'Project description: dolor sit amet, consectetur adipiscing elit, sed do',
            },
            {
                id: 18,
                name: 'Project Name',
                image_path: '/assets/studio/3.jpg',
                type: 'senior',
                link: '',
                description: 'Project description: dolor sit amet, consectetur adipiscing elit, sed do',
            },
        ];
    }

    getTools() {
        return this.tools;
    }

    setUnityLink(link: string) {
        this.tools[2].link = link;
    }

    getProjectById(id: number) {
        const tools = this.getTools();
        const fakeProjects = this.getFakeProjects();
        return [...tools, ...fakeProjects].find((tool: any) => tool.id === id);
    }

    getUnityLink() {
        return this.http.get(new Url('/drive/unity-download').path);
    }
}
